<template>
  <div class="animated fadeIn">
    <CModal :show.sync="showModal" size="lg" color="danger">

      <template #header>
        <h5 class="modal-title">{{ title }}</h5>
        <CButtonClose @click="$emit('cancelled',false)" class="text-white"/>
      </template>

      <div class="d-block text-center content-modal">
        <h3>Eliminar {{ this.title.toLowerCase() }} </h3>
        <p>¿Quieres eliminar definitivamente {{ this.element }}?  </p>
      </div>

       <template #footer>
        <CButton @click="$emit('cancelled',false)" color="outline-warning">Cancelar</CButton>
        <CButton @click="$emit('deleteItems',false)" color="danger">
          <!-- <CIcon name="cil-check-circle" />  -->
          Eliminar
        </CButton>
      </template>

    </CModal>
  </div>
</template>

<script>

export default {
  name: 'DeleteModal',
  props: {
    title: { type: String, default: 'Eliminar', required: true },
    show: { type: Boolean, default: false, required: true },
    element: { type: String, default: 'Elemento', required: true},
  },
  computed: {
    showModal() {
      return this.show;
    },
  },
}
</script>
