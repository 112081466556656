<template>
  <div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueNotifications from 'vue-notifications'
import miniToastr from 'mini-toastr'// https://github.com/se-panfilov/mini-toastr

const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
}

miniToastr.init({types: toastTypes})

function toast ({title, message, type, timeout, cb}) {
  return miniToastr[type](message, title, timeout, cb)
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
}

Vue.use(VueNotifications, options)

export default {
  name: 'toastr',
  props: ['title', 'message', 'type', 'timeout'],
  notifications: {
    show: {}
  },
  methods: {
    showToast(title, message, type, timeout) {
        this.show({title: title, message: message, type: type, timeout: timeout})
    }
  }
}
</script>
